<template>
  <section class="section keep-footer-off-page">
    <div class="container">
      <div class="columns is-centered">
        <!-- Card -->
        <div class="column cardSize is-two-thirds box p-5">
          <b-switch v-model="isDarkMode" @input="toggleTheme">
            Dark Mode
          </b-switch>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "settings-view",
  methods: {
    setTheme(theme) {
      this.$store.commit("setTheme", theme);
      localStorage.setItem("user-theme", theme);
    },
    toggleTheme() {
      const activeTheme = this.userTheme;
      if (activeTheme === "light-theme") {
        this.setTheme("dark-theme");
      } else {
        this.setTheme("light-theme");
      }
    },
  },
  computed: {
    ...mapGetters(["userTheme"]),
    isDarkMode: {
      get() {
        if (this.$store.getters.userTheme == "dark-theme") {
          return true;
        }
        return false;
      },
      set() {},
    },
  },
};
</script>

<style scoped>
.cardSize {
  max-width: 750px;
}
</style>
